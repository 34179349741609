.InfoBoxRowContainer{
    display: flex;       
    flex-direction: row;        
    align-items: stretch;
    align-items: center;
}

@media only screen and (max-width: 600px) {
  form {
    padding-left: 7px;
    padding-right: 7px;
  }
}

.InfoBoxRowContainer label {
    width: 50%;
    margin: 0px;
    text-align: left;   
    padding: 0px;
}

.InfoBoxRowContainer label:nth-child(2) {
    width: 50%;
    margin: 0px;
    padding-left: 7px;
}

.InfoBoxRowContainer .Testo {
    width: 50%;
    margin: 0px;
    text-align: left;   
    padding: 0px;
}

.InfoBoxRowContainer .IconaMappa {
    width: 50%;
    margin: 0px;
    text-align: right;   
    padding: 0px;
}

.InfoBoxRowContainer .Testo:nth-child(2) {
    width: 50%;
    margin: 0px;
    text-align: left;   
    padding-left: 7px;
}