.programma_container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.programma_container i {
    cursor:pointer;
}

.seleziona_data{
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.seleziona_data span{
    font-size: 1.6rem;
    font-weight: 600;
}

.scegli_ancora {
    margin-top: 7px;
    font-weight: 600;
}