.seleziona_data{
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.seleziona_data span{
    font-size: 1.4rem;
    font-weight: 600;
}

.programma_container span{
    font-size: 1.2rem;
    font-weight: 400;
}