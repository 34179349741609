/*ELEMENTI DOM*/
label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
  font-size: 18px !important;
}

body {
  display: flex;
  flex-direction: column;
}

input::placeholder {
  opacity: .8 !important;
  color: #bbbbbb !important;
}

/* @media screen and (min-width: 1025px) {
  body {
    background-image: url("https://www.telegraph.co.uk/content/dam/Travel/ski/Resorts/zell-am-see-skier-piste.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
} */

/* PREZZI */
.prezzo_totale {
  font-size: 20px;
  font-weight: bold;
}

.prezzo_parziale {
  font-size: 16px;
  font-weight: bold;
}

/* SELECTION GROUP */
.select_element {
  border-color: rgba(34, 36, 38, 0.15) !important;
  cursor: pointer;
}

.select_element.active {
  border-width: 3px !important;
  border-color: rgb(38, 143, 206) !important;
  background-color: red;
}

.schedaCorsoCard.active {
  border: 10px rgb(38, 143, 206) !important;
  border-bottom-width: 10px !important;
}

/*PRIVATE*/
.dummy_place_holder {
  height: 0px;
  margin: 0px;
  padding: 0px;
}

/* OVERRIDE Semantic UI - React*/
.ui.segment {
  border-radius: 4px !important;
  background-color: #e8e8e8;
}

.ui.cards > .card, .ui.card{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/*Footer */

.skiwork-footer {
  flex-shrink: 0;
  background-color: white;
  color: #000000a0;
  border-top: #00000030 1px solid;
  padding: 5px;
  margin-top: 0px;
}

.skiwork_footer_mobile {
  position: sticky;
  background-color: white;
  color: #000000a0;
  border-top: #00000030 1px solid;
  bottom: 0px;
  padding: 5px;
  min-height: 100px;
  
}

.skiwork_main {
  flex: 1 0 auto;
  padding: 0px;
}

.skiwork-image {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.riquadro-condizioni-e-privacy{
  background-color: #ffffffff !important;
  color: #000000a0 !important;
}

.testata {
  position: fixed;
  top: 0px;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 100px;
  background-color: rgba(210, 210, 210, 0.85);
}

.skiwork-lang-menuitem.active {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.head-offset {
  height: 110px;
}

.skiwork-card-header {
  color: rgba(48, 48, 48, 0.87) !important;
}

.skiwork-card-subheader {
  color: rgba(48, 48, 48, 0.5) !important;
}

.dicitura-sconto{
  margin-top: -7px;
  margin-bottom: 0px;
}

/*CUSTOM*/
/*********************************/
/*TESTATA SEZIONE*/
/* se non specificata, prende il colore di .ui.segment
/*********************************/
.testata-logo {
  margin-top: -5px;
  margin-left: 0px;
  max-width: 450px !important;
  width: 100% !important;
  z-index: 0;
}

.testata {
  height: 110px;
  background-color: rgba(56, 95, 169, 0.85);
}

.skiwork-header-pagina {
  background-color: #13387f !important;
}

/*Colore icona + Titolo*/
.skiwork-header-pagina .ui {
  color: #fff !important;
}

/*Colore sottotitolo*/
.skiwork-header-pagina .sub.header {
  color: #fff !important;
}
/*****************************************
/*TITOLI INTERNI*/
/*****************************************/
.skiwork-header {
  background-color: #ccc;
  color: #3e3e3e;
  font-size: 1.5em;
  font-weight: bold;
}

/***************************************
/*PULSANTI
/*****************************************/
.ui.primary.button {
  background-color: #3268d1 !important;
  color: white !important;
}

.contenitore-grande{
  margin-top: -14px !important;
}

/***************************************
/*FOOTER
/*****************************************/
.footer-logo-container{
  display: none;
}

.footer-logo-container-up{
  display: none;
}

.skiwork-footer {
  background-color: #000 !important;
  color: white !important;
  text-align: center !important;
  min-height: 80px;
}

.footer_code_button{
  font-size: 16px !important;
  padding: 0;
  margin:0;
}

.skiwork_footer_mobile .button{
  padding: 0.5em 0.5em 0.5em !important;
}

.lingue_mobile_container{
  
  background-color: greenyellow;
}

.lingue_mobile_button{
  position: absolute;
  z-index: 10000;
  right: 2px;
  top: 20px;
  padding: 2px !important;
  width: 40px;
}

.InfoWebContainer{
  text-align: justify;
}

.container-tipopagamento{
  display: flex;
  justify-content: center;
  
}

.form-tipopagamento{
  text-align: right;
  width: max-content;  
}

.field-tipopagamento{
  text-align: left;
}

.field-tipopagamento.scalapay{
  display: flex;
  align-content: start;
  justify-content: start;
}

.scalapay-logo{
  width: 110px; 
  margin-top: -3px;
  margin-left: 10px;
}

.scalapay-logo-small{
  width: 80px; 
  margin-top: 0px;
  margin-left: 10px;
}

.field-tipopagamento:last-child{
  margin-bottom: 20px !important;
}

.scalapay_corso{
  display: flex;  
  justify-content: start;  
  margin-top: -18px;
  margin-bottom: 14px;
}

#form-tipopagamento label{
  font-weight: 300;
  background-color: transparent;
}

#form-tipopagamento label img{
  width: 110px !important;
  height: auto !important;
}

.label-prezzo{
  margin-right: 7px;
}

.prezzo-barrato{
  text-decoration: line-through;
}

.riga-prezzo{
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between ;
}

@media screen and (min-width: 600px) {
  .DayPicker {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .testata {
    position: static;
  }
  .testata-logo {
    max-height: 85px;
  }

  .head-offset {
    height: 0px;
    display: none;
  }

  .padding_5_lr {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .padding_0_lr {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .padding_0_top {
    padding-top: 0px !important;
  }
}
