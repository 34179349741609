/* DEFAULT DAY */
.CalendarDay__default {
  border: 1px solid #b3ffb8;
  color: #484848;
  background: #e2ffe4;
}

.CalendarDay__default:hover {
  border: 1px solid #b3ffb8;
  color: #484848;
  background: #a0ffa7;
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}

.CalendarDay__highlighted_calendar {
  border: 1px solid #33ff41;
  background: #63ff6e;
  color: #484848;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  border: 2px double #33ff41;
  background: #1aff29;
  color: white;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #6adaff; /*background */
  color: #484848; /*text*/
  border: 1px solid #4dd2ff; /*default styles include a border*/
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: rgb(38, 143, 206);
  color: #484848;
  border: 1px solid #0099cc; /*default styles include a border*/
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #00c0ff;
  color: white;
}

.CalendarDay__hovered_offset {
  background: #00c0ff;
  border: 1px double #005d7c;
  color: inherit;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #a2e8ff;
}

.CalendarMonth_table {
  margin-top: 5px;
}
