.seleziona_giorno_container{
    justify-content: end;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    margin-top: 24px;
}

.seleziona_giorno_container p{
    margin-bottom: 0;
    font-weight: 600;
}

.calendario_container .CalendarDay__default
{
    background-color: red !important;
}